import {useLocation, useNavigate} from 'react-router-dom';
import React from "react";
import {Language} from "../models/Language";
import {Track} from "../models/Track";
import {ReactComponent as IconElevator} from "../images/icon-elevator.inline.svg";
import {ReactComponent as IconAdult} from "../images/icon-version-adult.inline.svg";
import {ReactComponent as IconChild} from "../images/icon-version-child.inline.svg";
import {RxCaretLeft} from "react-icons/rx";
import {LanguageVersion} from "../models/LanguageVersion";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {IoPlay} from "react-icons/io5";
import {motion} from 'framer-motion';
import {Radio} from "react-loader-spinner";
import useLanguagesStore from "../stores/useLanguagesStore";
import LanguageSelectorButton from "../components/LanguageSelectorButton";

const ElevatorScreen: React.FC = () => {
    const {t} = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();
    const {language, version} = location.state as { language: Language, version: LanguageVersion };

    const goToWelcomeScreen = () => {
        navigate('/');
    };

    const goToVersionSelectionScreen = () => {
        navigate('/version', {
            state: {
                language,
            }
        });
    };

    const goToTracklist = async () => {
        //setPlayDoorAnim(true);
        await Promise.any([
            new Promise(resolve => setTimeout(resolve, 3000)),
            useLanguagesStore.getState().fetchAndSetTracks(version),
        ]);

        navigate('/tracklist', {
            state: {
                language,
                version,
            }
        });
    }

    const [playDoorAnim, setPlayDoorAnim] = React.useState(false);

    return (
        <div className="scroll-wrapper">
            <div className={`bg-brand-theme-200  min-h-screen
                        |  px-16`}>
                <div className="content-wrapper  |  flex  flex-col  items-center  justify-center">
                    <div className="elevator-anim">
                        <div className="h-10"/>
                        <IconElevator className={`text-brand-grey-400  w-36`}/>
                        {playDoorAnim &&
                            <div className={`doors-container`}>
                                <motion.div className="absolute  inset-0  bg-brand-grey-700"
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            transition={{delayChildren: 0, delay: 1, duration: 1, ease: "easeInOut"}}
                                />
                                <motion.div className="doors"
                                            initial={{translateY: 0}}
                                            animate={{translateY: -400}}
                                            transition={{delay: 2, duration: 1, ease: "easeIn"}}
                                >
                                    <motion.div className="door door-left"
                                                initial={{translateX: -100}}
                                                animate={{translateX: 0}}
                                                exit={{translateX: -100}}
                                                transition={{duration: 1, ease: "easeInOut"}}
                                    />

                                    <motion.div className="door door-right"
                                                initial={{translateX: 100}}
                                                animate={{translateX: 0}}
                                                exit={{translateX: 100}}
                                                transition={{duration: 1, ease: "easeInOut"}}/>
                                </motion.div>

                                <motion.div className="absolute  inset-0  z-1
                                                |  flex  flex-col  items-center  justify-center"
                                            initial={{opacity: 0}}
                                            animate={{opacity: 0.5}}
                                            exit={{opacity: 0}}
                                            transition={{duration: 0.3}}
                                >
                                    <Radio
                                        visible={true}
                                        height={50}
                                        ariaLabel="radio-loading"
                                        wrapperStyle={{}}
                                        colors={["#8AABD1", "#8AABD1", "#8AABD1"]}
                                        wrapperClass="radio-wrapper"
                                    />
                                </motion.div>
                            </div>}
                    </div>

                    <div className="h-10"/>

                    <LanguageSelectorButton
                        language={language}
                        onClick={goToWelcomeScreen}
                    />


                    {language.versions.length > 1 && <div className={`w-full`}>
                        <div className="h-10"/>
                        <button className={`button  is-secondary  w-full  !py-2
                                |  flex  flex-row  items-center  justify-center
                                |  space-x-1
                                `}
                                onClick={goToVersionSelectionScreen}
                        >
                            <RxCaretLeft size={32} className={`text-bg-front`}/>
                            <span className={`type-switch`}>
                                <span
                                    className={`type-switch-icon  |  ${(version.type == 'adult') ? 'is-active' : ''}`}>
                                    <IconAdult className={`h-full  mx-auto`}/>
                                </span>

                                <span
                                    className={`type-switch-icon  |  ${(version.type == 'children') ? 'is-active' : ''}`}>
                                    <IconChild className={`h-full  mx-auto`}/>
                                </span>
                            </span>
                            <span className="block  w-2"/>
                        </button>
                    </div>}

                    <div className="h-10"/>

                    <button className={`button  is-brand  w-full  
                                |  flex  flex-row  items-center  justify-center
                                |  space-x-3
                                `}
                            onClick={goToTracklist}
                    >
                        <span className="block  w-1"/>
                        <span className={`text-lg  font-bold  uppercase`}>
                            {t('controls.start')}
                        </span>
                        <IoPlay size={24} className={``}/>
                    </button>
                    <div className="h-10"/>
                </div>
            </div>
        </div>
    );
};

export default ElevatorScreen;
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {IoCloseOutline} from "react-icons/io5";

type ModalProps = {
    visible: boolean;
    onClose: () => void;
    children: React.ReactNode;
};

const backdropVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

const modalVariants = {
    hidden: { y: "20px", x: '-50%', scale: 0.95, opacity: 0 },
    visible: { y: "0", x: '-50%', scale: 1, opacity: 1 },
};

const Modal: React.FC<ModalProps> = ({ visible, onClose, children }) => {
    return (
        <AnimatePresence mode="wait">
            {visible && (
                <>
                    <motion.div
                        className="fixed  inset-0  backdrop-blur  bg-opacity-30  z-40"
                        variants={backdropVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        onClick={onClose}
                        transition={{ duration: 0.3 }}
                    />
                    <motion.div
                        className={`fixed  left-1/2
                                    |  content-max-width
                                    |  w-11/12    
                                    |  transform  -translate-x-1/2  -translate-y-1/2  
                                    |  bg-brand-theme-800  text-brand-theme-200  p-5  rounded-3xl  z-50`}
                        style={{ top: '40%', maxHeight: '90vh', overflowY: 'auto' }}
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        transition={{ duration: 0.3 }}
                        onClick={(e) => e.stopPropagation()} // Prevent click from bubbling to the backdrop
                    >

                        <button className={`absolute  top-5  right-5
                                            |  w-10  h-10  rounded-2xl  border-2  border-brand-theme-200
                                            |  flex  items-center  justify-center  
                                            |  text-brand-theme-200`}
                        onClick={(e) => {
                            e.preventDefault();
                            onClose();
                        }}>
                            <IoCloseOutline size={30}/>
                        </button>

                        {children}
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
};

export default Modal;

import {useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect} from "react";
import {Language} from "../models/Language";
import {ReactComponent as Logo} from "../images/logo-color.inline.svg";
import {ReactComponent as IconAdult} from "../images/icon-version-adult.inline.svg";
import {ReactComponent as IconChild} from "../images/icon-version-child.inline.svg";
import {RxCaretLeft} from "react-icons/rx";
import {LanguageVersion, LanguageVersionType} from "../models/LanguageVersion";

const LanguageSelectionScreen: React.FC = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const {language} = location.state as { language: Language };

    useEffect(() => {
        if ( language.versions.length === 1 ) {
            const version = language.versions[0];
            navigate('/elevator', {
                state: {
                    language,
                    version,
                }
            });
        }
    }, []);

    const goToElevator = (version: LanguageVersion) => {
        navigate('/elevator', {
            state: {
                language,
                version,
            }
        });
    };

    const goToWelcomeScreen = () => {
        navigate('/');
    };

    const adultVersion: LanguageVersion = language.versions.find((version: LanguageVersion) => version.type === 'adult') as LanguageVersion;
    const childrenVersion: LanguageVersion = language.versions.find((version: LanguageVersion) => version.type === 'children') as LanguageVersion;

    return (
        <div className="scroll-wrapper">
            <div className={`bg-front  min-h-screen
                        |  padding-front`
            }>
                <div className="content-wrapper">
                    <Logo onClick={(e) => {
                        e.preventDefault();
                        navigate('/');
                    }}/>
                    <div className="h-10"/>

                    <button className={`button  w-full  
                                |  flex  flex-row  items-center  justify-center
                                |  space-x-1
                                `}
                            onClick={goToWelcomeScreen}
                    >
                        <RxCaretLeft size={32} className={`text-bg-front`}/>
                        <span className={`text-bg-front  text-lg  font-bold`}>
                            {language.name}
                        </span>
                    </button>

                    <div className="h-10"/>

                    <div className="space-y-2">
                        <button
                            className={`button  is-inverted  w-full  !py-7
                                |  flex  flex-row  justify-center`}
                            onClick={() => goToElevator(adultVersion)}
                        >
                            <IconAdult className={`text-brand-theme-500  h-32`}/>
                        </button>

                        <button
                            className={`button  is-inverted  w-full  !py-7
                                |  flex  flex-row  justify-center`}
                            onClick={() => goToElevator(childrenVersion)}
                        >
                            <IconChild className={`text-brand-theme-500  h-32`}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LanguageSelectionScreen;
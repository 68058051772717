import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
    // load translation using http -> see /public/locales
    .use(HttpApi)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: '🇬🇧 English',
        debug: true,
        lng: '🇬🇧 English',
        backend: {
            loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/translation.json',
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;

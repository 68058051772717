import {useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useRef} from "react";
import {Language} from "../models/Language";
import {SortedGroupedTracks, Track, Tracks} from "../models/Track";
import {LanguageVersion} from "../models/LanguageVersion";
import useLanguagesStore from "../stores/useLanguagesStore";
import {
    filterTracksBySearchValue,
    flattenGroupedTracks,
    groupTracksByLeadingDigit,
    orderGroupedTracks
} from "../repos/trackRepo";
import TrackPlayer from "../components/TrackPlayer";
import {AnimatePresence, motion} from 'framer-motion';
import classNames from "classnames";
import {IoSearchSharp} from "react-icons/io5";
import {BsGlobe2} from "react-icons/bs";
import Modal from "../components/Modal";
import {useTranslation} from "react-i18next";
import LanguageSelectorButton from "../components/LanguageSelectorButton";

const TracklistScreen: React.FC = () => {
    const [currentTrack, setCurrentTrack] = React.useState<Track | null>(null);

    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [isSearchFocused, setIsSearchFocused] = React.useState(false);

    const {t} = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();

    const allTracks = useLanguagesStore((state) => state.tracks);

    const {language, version} = (location.state ?? {}) as { language: Language, version: LanguageVersion };

    let tracks = allTracks[`${language.name}-${version.type}`];
    if (!Array.isArray(tracks) || tracks == null || typeof tracks === "undefined") {
        tracks = []
    }
    let groupedTracks: Tracks = groupTracksByLeadingDigit(tracks);

    if (searchTerm.trim() !== "") {
        groupedTracks = filterTracksBySearchValue(groupedTracks, searchTerm)
    }


    let sortedGroupedTracks: SortedGroupedTracks = orderGroupedTracks(groupedTracks);
    const flattenedTracks = flattenGroupedTracks(sortedGroupedTracks);

    console.log("🎵 Tracks:", sortedGroupedTracks);

    const openModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);
    const goToWelcomeScreen = () => {
        navigate('/');
    };

    useEffect(() => {
        const scrollWrapper = document.querySelector('.scroll-wrapper');
        if (scrollWrapper) {
            scrollWrapper.scrollTop = 0;
        }

        if (tracks == null || tracks.length === 0) {
            navigate('/');
        }
    }, []);

    return (
        <div className={`scroll-wrapper`}>
            <div className={`bg-brand-grey-400  min-h-screen  
                        |  px-7  py-5
                        |  text-brand-theme-200
                        | `}>
                <div className="content-wrapper">
                    <div className="flex  flex-row  items-center
                            |  space-x-2">
                        <div className="relative  w-full">
                            <input type="search"
                                   ref={inputRef}
                                   inputMode="numeric"
                                   value={searchTerm}
                                   className="input  w-full"
                                   onFocus={() => setIsSearchFocused(true)}
                                   onBlur={() => {
                                       setTimeout(() => {
                                           setIsSearchFocused(false);
                                       }, 100);
                                   }}
                                   onChange={(e) => {
                                       setSearchTerm(e.target.value);
                                   }}
                            />
                            <div className="absolute  top-0  left-0  bottom-0
                                    |  flex  items-center  justify-center
                                    |  pl-3">
                                <IoSearchSharp size={20} className={`text-brand-theme-800`}/>
                            </div>
                        </div>

                        {isSearchFocused &&
                            <button className="p-2  text-brand-theme-200"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchTerm("");
                                        inputRef.current?.blur();
                                    }}>
                                {t('controls.cancel')}
                            </button>}

                        {!isSearchFocused &&
                            <button className="px-2  py-1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openModal();
                                    }}>
                                <BsGlobe2 size={28} className={`text-brand-theme-200`}/>
                            </button>}
                    </div>

                    <div className="h-10"/>


                    <div className="space-y-10">
                        {sortedGroupedTracks.size === 0 &&
                            <div className={`text-center  text-brand-theme-200`}>
                                {t('tracklist_screen.no_results')}
                            </div>
                        }

                        {Array.from(sortedGroupedTracks).map(([key, tracks]) => (
                            <div key={key} className="space-y-2">
                                <div className="w-full  flex  flex-row  items-center  space-x-6">
                                    <div className="w-full  h-0.5  bg-brand-theme-400"/>
                                    <div className="text-brand-theme-200  text-2xl  font-bold  text-center">
                                        {key}
                                    </div>
                                    <div className="w-full  h-0.5  bg-brand-theme-400"/>
                                </div>

                                <div className="h-2"/>

                                <div className="grid  grid-cols-3  gap-4  px-3">
                                    {tracks.map((track: Track) => {
                                        const rawFileName = track.name.replace(".mp3", "").replace(/[^\d]/g, '').replace(/^[\D]*/, '');
                                        const trackClass = classNames({
                                            "bg-brand-theme-500  text-brand-theme-200": currentTrack?.name === track.name,
                                            "bg-brand-theme-800  text-brand-theme-200": currentTrack == null || currentTrack?.name !== track.name,
                                        });

                                        return (
                                            <button key={track.name}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setCurrentTrack(track);
                                                    }}
                                                    className={`${trackClass}  px-1  py-3  rounded-2xl
                                             |  text-center  text-4xl  font-bold  leading-none`}>
                                                {rawFileName}
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>


                    <div className="h-24"/>

                    {currentTrack && <div className="h-40"/>}

                    <AnimatePresence mode="wait">
                        {currentTrack && <motion.div
                            initial={{translateY: 1000}}
                            animate={{translateY: 0}}
                            exit={{translateY: 1000}}
                            transition={{duration: 0.75, ease: "easeInOut"}}
                            className={`fixed  bottom-0  left-0  right-0
                                        |  bg-brand-theme-400  text-brand-theme-800  rounded-t-3xl  -shadow-2xl`}
                        >
                            <TrackPlayer
                                autoPlay={true}
                                track={currentTrack}
                                onNext={() => {
                                    const currentIndex = flattenedTracks.findIndex((track: Track) => track.name === currentTrack.name);
                                    const nextTrack = flattenedTracks[currentIndex + 1];
                                    if (nextTrack) {
                                        setCurrentTrack(nextTrack);
                                    }
                                }}
                                onPrevious={() => {
                                    const currentIndex = flattenedTracks.findIndex((track: Track) => track.name === currentTrack.name);
                                    const previousTrack = flattenedTracks[currentIndex - 1];
                                    if (previousTrack) {
                                        setCurrentTrack(previousTrack);
                                    }
                                }}
                                onEnded={async () => {
                                    //await new Promise(resolve => setTimeout(resolve, 3000));
                                    setCurrentTrack(null);
                                }}
                            />
                        </motion.div>}
                    </AnimatePresence>

                </div>

                <Modal visible={isModalVisible}
                       onClose={closeModal}>
                    <div className="h-14"/>

                    <LanguageSelectorButton
                        language={language}
                        onClick={goToWelcomeScreen}
                    />

                    <div className="h-6"/>

                    <button className={`button  is-brand  uppercase  w-full  font-bold`}
                            onClick={closeModal}>
                        {t('controls.ok')}
                    </button>

                    <div className="h-6"/>
                </Modal>
            </div>
        </div>
    );
};

export default TracklistScreen;
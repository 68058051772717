import {RxCaretLeft} from "react-icons/rx";
import React from "react";
import {Language} from "../models/Language";

type LanguageSelectorButtonProps = {
    onClick: () => void,
    language: Language,
}

const LanguageSelectorButton: React.FC<LanguageSelectorButtonProps> = ({onClick, language}) => {
    return <button className={`button  is-secondary  w-full  
                                |  flex  flex-row  items-center  justify-center
                                |  space-x-1
                                `}
                   onClick={onClick}
    >
        <RxCaretLeft size={32} className={`text-bg-front`}/>
        <span className={`text-bg-front  text-lg  font-bold`}>
            {language.name}
        </span>
        <span className="block  w-2"/>
    </button>;
}

export default LanguageSelectorButton;
export const fetchLanguages = async () => {
    try {
        const response = await fetch('/api/languages');
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch languages:", error);
        return null;
    }
};
import {LanguageVersion} from "../models/LanguageVersion";
import {SortedGroupedTracks, Track, Tracks} from "../models/Track";

export const fetchTracks = async (languageVersion: LanguageVersion): Promise<Track[] | null> => {
    try {
        // Construct the URL-encoded folder name
        const response = await fetch(`/api/tracklist?name=${encodeURIComponent(languageVersion.name)}&type=${encodeURIComponent(languageVersion.type)}&order=${encodeURIComponent(languageVersion.order)}`);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const tracks = await response.json();
        console.log("💿 Tracks:", tracks);

        return tracks;
    } catch (error) {
        console.error("Failed to fetch tracks:", error);
        return null;
    }
}

export const groupTracksByLeadingDigit = (tracks: Track[]): Tracks => {
    const groupedTracks: Tracks = {};

    const _tracks = [...tracks];

    if (!_tracks) {
        return groupedTracks;
    }

    _tracks.forEach((track) => {
        // Remove all alphabetical characters from the name
        const cleanedName = track.name.replace(/[^\d]/g, '');

        // Remove any special characters at the start of the name
        const nameStartingWithDigit = cleanedName.replace(/^[\D]*/, '');

        // Use the first digit as the key for grouping
        const groupKey = nameStartingWithDigit.substring(0, 1);

        if (!groupedTracks[groupKey]) {
            groupedTracks[groupKey] = [];
        }

        groupedTracks[groupKey].push(track);
    });

    return groupedTracks;
}

export const orderGroupedTracks = (groupedTracks: Tracks): SortedGroupedTracks => {
    // Sort the groups by the leading digit in descending order
    const sortedKeys = Object.keys(groupedTracks).sort((a, b) => parseInt(b) - parseInt(a));
    const sortedGroupedTracks: SortedGroupedTracks = new Map();

    sortedKeys.forEach((key) => {
        // Sort the tracks within each group in ascending order
        const sortedTracks = orderTracks(groupedTracks[key]);

        sortedGroupedTracks.set(key, sortedTracks);
    });

    return sortedGroupedTracks;
};

export const orderTracks = (tracks: Track[]): Track[] => {
    return tracks.sort((trackA, trackB) => {
        const nameA = parseInt(trackA.name.replace(/[^0-9]/g, ''));
        const nameB = parseInt(trackB.name.replace(/[^0-9]/g, ''));
        return nameA - nameB;
    });
}

export function filterTracksBySearchValue(groupedTracks: Tracks, searchValue: string): Tracks {
    // Object to hold the filtered groups
    const filteredGroups: Tracks = {};

    // Iterate over each group
    Object.keys(groupedTracks).forEach((groupKey) => {
        // Filter the tracks in the group
        const filteredTracks = groupedTracks[groupKey].filter((track) =>
            track.name.includes(searchValue)
        );

        // Only add the group to the filteredGroups if it has any tracks left after filtering
        if (filteredTracks.length > 0) {
            filteredGroups[groupKey] = filteredTracks;
        }
    });

    return filteredGroups;
}

export function flattenGroupedTracks(groupedTracks: SortedGroupedTracks): Track[] {
    // Convert the Map values to an array of track arrays
    const trackGroups = Array.from(groupedTracks.values());

    // Flatten the array of track arrays into a single array of tracks
    const flattenedTracks = trackGroups.reduce((acc, group) => {
        // Concatenate the current group with the accumulated array
        return [...acc, ...group];
    }, [] as Track[]);

    return flattenedTracks;
}
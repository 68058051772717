import {create} from 'zustand';
import {LanguagesState} from "../models/LanguagesState";
import {fetchLanguages} from "../repos/languageRepo";
import {LanguageVersion} from "../models/LanguageVersion";
import {Language} from "../models/Language";
import {fetchTracks} from "../repos/trackRepo";

const useLanguagesStore = create<LanguagesState>((set) => ({
    currentLanguage: null,
    currentVersion: null,
    languages: [],
    tracks: {},
    setCurrentLanguage: (language) => set({currentLanguage: language}),
    setCurrentVersion: (version) => set({currentVersion: version}),
    setLanguages: (languages) => set({languages}),
    fetchAndSetLanguages: async () => {
        const data: LanguageVersion[][] = await fetchLanguages();
        const languages: Language[] = [];
        Object.entries(data).forEach(([key, value]) => {
            languages.push(
                {
                    order: value[0].order,
                    name: key,
                    versions: (value as LanguageVersion[]).reverse(),
                }
            );
        });
        console.log(languages);
        if (languages) {
            set({languages});
        }
    },
    fetchAndSetTracks: async (languageVersion: LanguageVersion) => {
        const data = await fetchTracks(languageVersion);
        if (data) {
            set({tracks: {[`${languageVersion.name}-${languageVersion.type}`]: data}});
        }
    }
}));

export default useLanguagesStore;
import {useNavigate} from 'react-router-dom';
import React, {useEffect} from "react";
import {ReactComponent as Logo} from "../images/logo-color.inline.svg";
import {ReactComponent as IconAudioguide} from "../images/icon-audioguide-ccolor.inline.svg";
import {useTranslation} from "react-i18next";
import {Radio} from "react-loader-spinner";
import useLanguagesStore from "../stores/useLanguagesStore";
import {motion} from 'framer-motion';
import {LanguageVersion} from "../models/LanguageVersion";
import {Language} from "../models/Language";
import i18n from "../i18n";

const WelcomeScreen: React.FC = () => {
    const {t} = useTranslation();

    let navigate = useNavigate();

    const [showLoading, setShowLoading] = React.useState(true);
    const languages = useLanguagesStore((state) => state.languages);

    useEffect(() => {

        if (languages.length > 0) {
            setShowLoading(false);
            return;
        }

        const startTime = Date.now();
        const fetchData = async () => {
            setShowLoading(true);
            await useLanguagesStore.getState().fetchAndSetLanguages();
            const endTime = Date.now();

            const waitingTime = 750;
            // if time was shorter than `waitingTime`, wait the remaining time.
            // This artificial delay is to make sure the loading screen is visible
            // without jarring the user.
            if ((endTime - startTime) < waitingTime) {
                await new Promise(resolve => setTimeout(resolve, waitingTime - (endTime - startTime)));
                setShowLoading(false);
            }
            console.log("✅ Fetched languages");
        }

        fetchData();
    }, []);

    console.log("🌎 Languages:", languages);

    const languageCount = languages.length;

    const containerVariants = {
        initial: {},
        animate: {
            transition: {
                staggerChildren: 0.08,
            },
        },
    };

    const itemVariants = {
        initial: {opacity: 0, translateY: 20},
        animate: {opacity: 1, translateY: 0},
        exit: {opacity: 0, height: 0, translateY: 20},
    };

    return (
        <div className="_scroll-wrapper">
            <div className={`bg-front min-h-screen  
                        |  padding-front`}>
                <div className="content-wrapper  |  flex  flex-col">
                    <Logo onClick={(e) => {
                        e.preventDefault();
                        navigate('/');
                    }}/>
                    <div className="h-10"/>

                    <div className="flex  flex-row  space-x-4  items-center">
                        <div className="w-20  shrink-0  p-2  |  bg-brand-theme-800  rounded-2xl">
                            <IconAudioguide className={`w-full  text-brand-theme-200`}/>
                        </div>
                        <div className="grow">
                            <div className="text-base  font-bold">
                                {t('welcome_screen.title')}
                            </div>
                        </div>
                    </div>

                    <div className="h-10"/>

                    {(languageCount <= 0 || showLoading) &&
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0, scale: 0.9}}
                            transition={{duration: 0.45}}
                            className="flex  flex-col  items-center">
                            <Radio
                                visible={true}
                                height={50}
                                ariaLabel="radio-loading"
                                wrapperStyle={{}}
                                colors={["#B53D2B", "#B53D2B", "#B53D2B"]}
                                wrapperClass="radio-wrapper"
                            />
                            <div className="h-1"/>
                            <div className="text-brand-theme-800  text-sm  text-center">
                                {t('controls.loading')}
                            </div>
                        </motion.div>}

                    {(languageCount > 0 && !showLoading) &&
                        <motion.div
                            variants={containerVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{duration: 0.4}}
                            className="flex  flex-col  items-center  space-y-3"
                        >
                            {languages.map((language: Language, index) => {
                                return <motion.button
                                    key={language.name}
                                    variants={itemVariants}
                                    transition={{duration: 0.45}}
                                    aria-label={`button-${language.name}`}
                                    className="button  is-inverted  w-full"
                                    onClick={() => {
                                        useLanguagesStore.getState().setCurrentLanguage(language);

                                        i18n.changeLanguage(language.name);

                                        if ( language.versions.length === 1 ) {
                                            const version = language.versions[0];
                                            navigate('/elevator', {
                                                state: {
                                                    language,
                                                    version,
                                                }
                                            });
                                            return;
                                        }

                                        navigate('/version', {state: {language: language}});
                                    }}
                                >
                                    <div className="flex  flex-col  items-center">
                                        <div className="_text-brand-theme-800  _text-sm  font-bold">
                                            {language.name}
                                        </div>
                                    </div>
                                </motion.button>;
                            })}
                        </motion.div>}

                    <div className="h-25"/>
                </div>
            </div>
        </div>
    );
};

export default WelcomeScreen;

import React from 'react';
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import WelcomeScreen from "./screens/WelcomeScreen";
import VersionSelectionScreen from "./screens/VersionSelectionScreen";
import './i18n';
import TracklistScreen from "./screens/TracklistScreen";
import ElevatorScreen from "./screens/ElevatorScreen";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    const basename = process.env.REACT_APP_BASENAME || '/';
    return (
        <BrowserRouter basename={basename}>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<WelcomeScreen/>}/>
                <Route path="/version" element={<VersionSelectionScreen/>}/>
                <Route path="/elevator" element={<ElevatorScreen/>}/>
                <Route path="/tracklist" element={<TracklistScreen/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;

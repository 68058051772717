import {Track} from "../models/Track";
import React, {useEffect, useRef, useState} from "react";
import {IoPlay, IoPlayForward, IoPlayBack, IoPause} from "react-icons/io5";
import {Scrubber, ScrubberProps} from 'react-scrubber';
import 'react-scrubber/lib/scrubber.css'
import {AnimatePresence} from "framer-motion";
import {Radio} from "react-loader-spinner";

export type PlayerProps = {
    autoPlay?: boolean;
    track: Track;
    onNext: () => void;
    onPrevious: () => void;
    onEnded: () => void;
}

function formatDuration(seconds: number): string {
    // Calculate minutes and seconds from the total seconds
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);

    // Pad the minutes and seconds with leading zeros if necessary
    const formattedMins = mins.toString().padStart(2, '0');
    const formattedSecs = secs.toString().padStart(2, '0');

    // Return the formatted duration
    return `${formattedMins}:${formattedSecs}`;
}

const TrackPlayer: React.FC<PlayerProps> = ({track, onNext, onPrevious, onEnded, autoPlay}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if (audioRef.current) {
            isPlaying ? audioRef.current.play() : audioRef.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        setProgress(0);
        if (audioRef.current) {
            setIsLoading(true);
            audioRef.current.load();
            setIsPlaying(false);

            if (autoPlay) {
                setIsPlaying(true);
                if (audioRef.current.paused) {
                    audioRef.current.play();
                }
            }
        }
    }, [track]);

    useEffect(() => {
        // When metadata is loaded, update the duration
        const audio = audioRef.current;
        const setAudioData = () => {
            setDuration(audio?.duration || 0);
        };

        // When time updates, update the current time
        const setAudioTime = () => {
            setCurrentTime(audio?.currentTime || 0);
        };

        audio?.addEventListener('loadedmetadata', setAudioData);
        audio?.addEventListener('timeupdate', setAudioTime);

        // Cleanup listeners on unmount
        return () => {
            audio?.removeEventListener('loadedmetadata', setAudioData);
            audio?.removeEventListener('timeupdate', setAudioTime);
        };
    }, []);

    const togglePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const currentProgress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
            setProgress(currentProgress);
        }
    };

    const handleScrub = (value: number) => {
        if (audioRef.current) {
            const time = (value / 100) * audioRef.current.duration;
            audioRef.current.currentTime = time;
            setProgress(value);
        }
    };

    const handleEnded = () => {
        setIsPlaying(false);
        onEnded();
    };

    return (
        <div className={`px-10  py-7
                        |  flex  flex-col  items-center  text-center`}
        >
            <audio ref={audioRef}
                   onTimeUpdate={handleTimeUpdate}
                   onEnded={handleEnded}
                   onLoadedData={() => setIsLoading(false)}
            >
                <source src={track.url} type="audio/mpeg"/>
                Your browser does not support the audio element.
            </audio>

            <div className={`text-2xl  font-bold`}
                 onClick={onEnded}
            >{track.name}</div>

            <div className="h-5"/>

            <Scrubber
                min={0}
                max={100}
                value={progress}
                onScrubStart={handleScrub}
                onScrubEnd={handleScrub}
                onScrubChange={handleScrub}
            />
            {audioRef.current != null &&
                <div className="w-full  flex  flex-row  items-center  justify-between
                            |  text-brand-theme-800">
                    <div className="text-sm">{formatDuration(currentTime)}</div>
                    <div className="w-2"/>
                    <div className="text-sm">{formatDuration(duration)}</div>
                </div>}

            <div className="h-5"/>

            <div className="flex  flex-row  space-x-10">
                <button onClick={onPrevious}><IoPlayBack size={32} className={`text-brand-theme-200`}/></button>

                <div className="relative  transition  transition-opacity">
                    <button onClick={togglePlayPause}
                    style={{
                        position: 'relative',
                        top: 4,
                        opacity: isLoading ? 0 : 1,
                    }}>
                        {isPlaying ? <IoPause size={44}/> : <IoPlay size={44}/>}
                    </button>

                    {isLoading &&
                        <div className="absolute  inset-0  -top-2  flex  items-center  justify-center">
                            <Radio
                                visible={true}
                                height={50}
                                ariaLabel="radio-loading"
                                wrapperStyle={{}}
                                colors={["#D4C4B3", "#D4C4B3", "#D4C4B3"]}
                                wrapperClass="radio-wrapper"
                            />
                        </div>
                    }
                </div>

                <button onClick={onNext}><IoPlayForward size={32} className={`text-brand-theme-200`}/></button>
            </div>
        </div>

    );
};

export default TrackPlayer;